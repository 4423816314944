import React, { useState } from "react";

const ListComponent = ({ listData }) => {
  return (
    <div>
      <ul className="flex flex-col divide-y-2 pt-2 pb-2 text-black">
        {listData.map((item, index) => (
          <li key={index} className="border-gray-200 flex flex-row mb-2">
            <a href={item.link} className="w-full">
              <div className="select-none cursor-pointer rounded-md flex flex-1 items-center p-4  transition duration-500 ease-in-out transform hover:-translate-y-1 hover:shadow-lg">
                <div className="flex flex-col rounded-md w-12 h-12 bg-gray-300 justify-center items-center mr-4">
                  <img src={item.logo} alt="logo"></img>
                </div>
                <div className="flex-1 pl-1">
                  <div className="text-gray-600 text-sm md:text-md">
                    {item.title}
                  </div>
                  <div className="text-gray-600 text-sm">{item.distance}</div>
                </div>
                <div className="text-gray-600 text-xs">{item.driveTime}</div>
              </div>
            </a>
          </li>
        ))}
      </ul>
    </div>
  );
};

export default ListComponent;
