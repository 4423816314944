// s3.js
const AWS = require("aws-sdk");

AWS.config.update({
  accessKeyId: "AKIAY4XPV2GL6CFWOD4B",
  secretAccessKey: "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",
  region: "us-west-2",
});

const s3 = new AWS.S3();

module.exports = s3;
