// ImageGallery.js
import React, { useEffect, useState } from "react";
import s3 from "../s3";

function ImageGallery() {
  const [images, setImages] = useState([]);
  const [isOpen, setIsOpen] = useState(false);
  const [selectedImage, setSelectedImage] = useState(null);

  const bucketURL = "https://mystique-uh-storage.s3.us-west-2.amazonaws.com";

  useEffect(() => {
    // Fetch image URLs from S3 bucket and update the 'images' state
    async function fetchImages() {
      try {
        const s3Response = await s3
          .listObjectsV2({
            Bucket: "mystique-uh-storage",
            Prefix: "assets/gallery/", // Specify the prefix for the folder
          })
          .promise();

        const imageKeys = s3Response.Contents.map((object) => object.Key);
        imageKeys.shift();
        setImages(imageKeys);
      } catch (error) {
        console.error("Error fetching images from S3:", error);
      }
    }

    fetchImages();
  }, []);

  const openImage = (imageKey) => {
    setSelectedImage(imageKey);
    setIsOpen(true);
  };

  const closeImage = () => {
    setSelectedImage(null);
    setIsOpen(false);
  };

  return (
    <div className="grid grid-flow-row grid-cols-1 sm:grid-cols-2 md:grid-cols-3 xl:grid-cols-4 w-full">
      {images.map((imageKey) => (
        <div
          key={imageKey}
          onClick={() => openImage(imageKey)}
          className="relative hover:scale-105 cursor-pointer"
        >
          <img
            src={`${bucketURL}/${imageKey}`}
            alt={imageKey}
            className="w-full h-auto"
          />
        </div>
      ))}
      {isOpen && (
        <div className="fixed inset-0 flex items-center justify-center z-50">
          <div className="absolute inset-0 bg-black opacity-75"></div>
          <div className="relative z-50">
            <button
              className="absolute top-4 right-4 text-white text-2xl"
              onClick={closeImage}
            >
              &#x2715;
            </button>
            <img
              src={`${bucketURL}/${selectedImage}`}
              alt={selectedImage}
              className="max-h-screen max-w-screen mx-auto"
            />
          </div>
        </div>
      )}
    </div>
  );
}

export default ImageGallery;
