import React from "react";
import Header from "../components/common/Header";
import Box from "../components/common/Box";
import ContactProperty from "../components/ContactProperty";
import Footer from "../components/common/Footer";
import { useState } from "react";

function Floorplans() {
  const [floorplans, setFloorplans] = useState([
    {
      id: 1,
      title: "Studio - 488 sqft",
      details: ["1 bed, 1 bath", "$300 deposit"],
      priceRange: "$899 - $1,284",
      image:
        "https://mystique-uh-storage.s3.us-west-2.amazonaws.com/assets/floorplans/studio488.jpeg",
    },
    {
      id: 2,
      title: "Studio - 493 sqft",
      details: ["1 bed, 1 bath", "$300 deposit"],
      priceRange: "$899 - $1,291",
      image:
        "https://mystique-uh-storage.s3.us-west-2.amazonaws.com/assets/floorplans/studio493.png",
    },
    {
      id: 3,
      title: "2x2 - 1020 sqft",
      details: ["2 bed, 2 bath", "$300 deposit"],
      priceRange: "$1200 - $2,175",
      image:
        "https://mystique-uh-storage.s3.us-west-2.amazonaws.com/assets/floorplans/twobed1020.png",
    },
    {
      id: 4,
      title: "2x2 - 1170 sqft",
      details: ["2 bed, 2 bath", "$300 deposit"],
      priceRange: "$1,350 - $2,234",
      image:
        "https://mystique-uh-storage.s3.us-west-2.amazonaws.com/assets/floorplans/twobed1170.png",
    },
  ]);

  return (
    <>
      <div className="w-screen h-full ">
        {" "}
        <div className="flex flex-col absolute">
          <section className="HEADER ">
            <Header />
          </section>
        </div>
        <div className="px-6 sm:px-40 pt-10 sm:pt-20 pb-10 sm:pb-20 bg-mystique">
          <h1 className="text-xl md:text-4xl font-bold mb-2 sm:mb-4 text-white mt-20">
            FLOOR PLANS
          </h1>
          <p className="mb-4 text-white text-xs md:text-lg">
            Explore our studio, one, and two bedroom floorplans.
          </p>
        </div>
        <section className="grid grid-cols-1 sm:grid-cols-2 gap-4 sm:gap-8 items-start justify-center my-20 px-6">
          <Box floorplans={floorplans} />
          <ContactProperty />
        </section>
        <Footer />
      </div>
    </>
  );
}

export default Floorplans;
